body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
}

header {
  color: #fff;
  background-color: #eceff1;
  padding: 33px 0 25px 15px;
}

header p {
  width: 400px;
  margin: 0 auto;
}
header .wrapper {
  display: flex;
  justify-content: space-between;
}

header .wrapper2 {
  display: flex;
  justify-content: right;
}

header h1 {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.3px;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  
}

.fa {
  font-size: 22px;
  transform: translateY(50deg);  
}

header i {
  vertical-align: middle;
  margin-left: 12px;
}

.container {
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 30px;
}

.wrapper {
  max-width: 1100px;
  margin: 0 auto;
}

.add-item {
  border-radius: 2px;
  border-top: 3px solid #103D5D;
  margin-right: 50px;
  background-color: rgb(237, 237, 237);
  padding: 60px 18px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
  margin-bottom: 34px;  
  width: 25%;
  margin-right: auto;
}

.display-item {
  width: 65%;
  margin-left: 50px;
}

.display-item button {
  margin-top: 20px;
  margin-left: auto;
  display: block;
}

.add-item form {
  width: 100%;
  overflow: hidden;
}

input {
  font-size: 22px;
  color: #000;
  padding: 18px 22px;
  font-size: 16px;
  margin-bottom: 17px;
  border: 0;
  display: block;
  width: 100%;  
}


h2 {
  padding: 0;
  margin: 0;
  font-weight: 400;
}




button {
  border-radius: 2px;
  min-width: 88px;
  background-color: #5f8dd3;
  cursor: pointer;
  border: 0;
  min-width: 120px;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  padding: 10px 6px;
}

.user-profile {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 100px;
  height: 100px;
}

.user-profile img {
  border-radius: 50%;
  max-width: 100%;
}

a{
color: #103D5D;    
}
 

a.hover{
  color: #fff;    
}

{/*
  color: #4098bf;    
ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-top: 0;
  padding: 0;
}

li {
  width: calc(50% - 50px);
  list-style-type: none;
  margin-right: 50px;
  background-color: rgb(237, 237, 237);
  color: #000;
  margin-bottom: 30px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
}

li h3 {
  background-color: #4A90E2;
  margin: 0;
  color: #fff;
  font-weight: 300;
  padding: 15px;
}

li p {
  padding: 15px;
}
*/}






.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.btn {
    white-space:normal !important;
    word-wrap: word-break;
    border: 0.5px solid silver;
}

.btn:hover, .btn:focus, .btn:active, .btn:hover:focus, .btn:active:focus, .btn:focus:active, .btn:focus:active:hover, .btn.active.focus.hover, .btn.active.focus, .btn.focus.active, .btn.focus, .btn.active, .open>.dropdown-toggle.btn {
    background-color: #5f8dd3;
}

.sticky_style {
      z-index: 10;
      position: relative;
      height: 100px;
}

.header {
padding: 10px;
margin: 0px;
}

.footer {
background-color: #5f8dd3;;
padding: 0px;
height: 5px;
}
.spacer {
padding: 0px;
height: 3px;
}
.footer-2 {
padding: 10px;
background-image:-webkit-linear-gradient(top,#3c3c3c 0,#222 100%);background-image:-o-linear-gradient(top,#3c3c3c 0,#222 100%);background-image:-webkit-gradient(linear,left top,left bottom,from(#3c3c3c),to(#222));background-image:linear-gradient(to bottom,#3c3c3c 0,#222 100%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);filter:progid:DXImageTransform.Microsoft.gradient(enabled=false);background-repeat:repeat-x;border-radius:4px;
color: rgba(255,255,255,.5);
text-align: center;
}


.footer-2 a:link{
color:rgba(255,255,255,0.5);
border-bottom: 1px rgba(255,255,255,0.5) dotted;
}

.footer-2 a:visited{
color:rgba(255,255,255,0.5);
}

.footer-2 a:hover{
color:rgba(255,255,255,0.5);
text-decoration: underline;
border: none;
}

.footer-2 a:active{
color:rgba(255,255,255,0.5);
}

.content {
margin: auto;
padding: 10px;
max-width: 1000px;
}

